/* purgecss start ignore */
@tailwind base;

h1,
h2 {
  @apply mb-2;
}

p {
  @apply mb-6;
}

@tailwind components;

/* purgecss end ignore */

@tailwind utilities;